import CartItem from "./cart_item"

export default class Cart {
    constructor() {
      this.items = []
      const items = document.querySelectorAll('.cart-item')
        items.forEach(item => {
          let _item = new CartItem(item)
          this.items.push(_item)
        })
        this.events()
    }

    events() {
      let inputs = document.querySelectorAll('.quantity-input')
      inputs.forEach(input => this.updateTotalEvent(input))
    }
    
    updateTotalEvent(input) {
      input.addEventListener("change", () => {
        let target = input.dataset.target
        let element = document.querySelector(target+"-quantity")
        let quantity = Number(input.value)
        element.innerText = quantity
        // Update total
        let element_total = document.querySelector(target+"-total")
        let price = Number(input.dataset.price)
        let total = price * quantity
        element_total.innerText = `$${(total / 100).toFixed(2)}`

        this.updateCartTotals()
      })
    }
    
    updateCartTotals() {
      
      this.updateTotal()
      this.updateSubtotal()
      this.updateIVA()
    }

    updateTotal() {
      const element = document.querySelector('#cart-total')
      element.innerText = `$${this.total}`
    }
    
    get total() {
      let items = document.querySelectorAll('.item-subtotal')
      let total = 0
      items.forEach(item => {
        const number = Number(item.innerText.replace('$', ''))
        total += number
      })
      return total.toFixed(2)
    }

    get iva() {
      return (this.total * 0.16).toFixed(2)
    }
    
    updateSubtotal() {
      const subtotal = document.querySelector('#cart-subtotal')
      subtotal.innerText = `$${(this.total - this.iva).toFixed(2)}`
    }
    
    updateIVA() {
      const element = document.querySelector('#cart-iva')
      element.innerText = `$${this.iva}`
    }

}