export default class CartItem {
  constructor(_container) {
    this.container = _container
    // this.quantity_input = this.container.querySelector('.quantity-input')
    this.destroy_checkbox = this.container.querySelector('.destroy-item')
    //
    // this.quantity_input.addEventListener("change", () => this.onQuantityChange())
    this.destroy_checkbox.addEventListener("change", () => this.onDestroyChange())
  }

  onDestroyChange(e) {
    const targetSelector = this.destroy_checkbox.dataset.target;
    const targetElement = document.querySelector(targetSelector);
    if (this.destroy_checkbox.checked) {
      targetElement.style.display = "none";
      targetElement.classList.add("disabled")
      this.removeDetails(targetSelector)
      this.updateCount()
    } else {
      targetElement.style.display = "";
    }
  }
  
  updateCount() {
    const counter = document.querySelector('#cart-items-count')
    if (counter) {
      let total = document.querySelectorAll('.cart-item:not(.disabled)').length
      counter.innerText = total
    }
    window["CART"].updateCartTotals()
  }
  
  removeDetails(_target) {
    const targetElement = document.querySelector(_target + "-detail")
    if (targetElement) {
      targetElement.style.display = "none";
      const subtotal = targetElement.querySelector('.item-subtotal')
      if (subtotal) {
      subtotal.innerText = 0
      subtotal.classList.add("disabled")
      }
    }
  }

  onQuantityChange() {
    let quantity = Number(this.quantity_input.value)
    let price = Number(this.container.dataset.price)
    let total = quantity * price
    this.container.querySelector('.total').innerText = `$${(total / 100).toFixed(2)}`
  }


}